import React, { useState, useEffect, useCallback } from "react";
import "./SI180Dashboard.css";
import { Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import LeftNav from "./LeftNav";
import {
  fetchCustomer,
  generateCheckNumber,
} from "../../services/api/si180Api";
import { setLoading } from "../../redux/publicReducer/actions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const itemImgSrc = process.env.REACT_APP_IMAGE_SRC;
const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const greyColor = process.env.REACT_APP_SI180_BGGREY;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const updateUserNames = (users) => {
  return users.map((user) => ({
    ...user, // Spread the other properties
    FirstName: capitalizeFirstLetter(user.FirstName).trim(),
    LastName: capitalizeFirstLetter(user.LastName).trim(),
  }));
};

const SI180Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("all-items");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerNumbers, setCustomerNumbers] = useState([]);

  const preloadImage = (url) => {
    return new Promise((resolve) => {
      if (!url) {
        resolve(null); // If the URL is null or undefined, resolve immediately
        return;
      }

      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => resolve(null); // Resolve with null if the image fails to load
      img.src = url;
    });
  };

  const selectedCustomer = async (user) => {
    const residentImg = user.SIC_Photo_Filename
      ? `${itemImgSrc}${user.SIC_Photo_Filename}`
      : null;

    // Check if the image exists
    const imageSrc =
      (await preloadImage(residentImg)) || `${itemImgSrc}respic.png`;

    Swal.fire({
      title: `<h5>You selected</h5>${user.FirstName} ${user.LastName}`,
      html: `
        <img src="${imageSrc}" 
          alt="${user.FirstName} ${user.LastName}" 
          style="
            width: 150px; 
            height: auto; 
            margin-bottom: 10px; 
            border-radius: 50%;
            border: 3px solid ${btnColor}"
        >
        <h5>Please confirm your selection.</h5>
      `,
      showCancelButton: true,
      confirmButtonColor: btnColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      customClass: {
        confirmButton: "swal-wide swal-button-font",
        cancelButton: "swal-wide swal-button-font",
        actions: "swal-actions-reverse",
      },
    }).then((result) => {
      if (result.value) {
        // Generate a new check number
        generateCheckNumber().then((data) => {
          sessionStorage.setItem("checkNumber", data.checkNumber);
        });
        sessionStorage.removeItem("cartItems");
        sessionStorage.setItem("selectedCustomer", JSON.stringify(user));
        history.push("/resident-menu");
      }
    });
  };

  const fetchData = useCallback(async () => {
    try {
      setUsers([]);
      dispatch(setLoading(true));
      const data = await fetchCustomer(searchTerm);
      const updatedUsers = updateUserNames(data);

      setUsers(updatedUsers);
      setCustomerNumbers(updatedUsers.map((user) => user.CustomerNumber));

      if (updatedUsers.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No record found.",
        });
      }

      dispatch(setLoading(false));
    } catch (error) {
      console.error("fetchCustomer failed:", error);
      dispatch(setLoading(false));
    }
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchTerm("all-items");
    }
    fetchData();
  }, [searchTerm, fetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [users]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const currentUser = {
    firstName: JSON.parse(sessionStorage.getItem("currentUser"))[0].FirstName,
    lastName: JSON.parse(sessionStorage.getItem("currentUser"))[0].LastName,
  };

  const renderUsers = users
    .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
    .map((user) => (
      <tr
        key={user.CustomerNumber}
        onClick={() => selectedCustomer(user)}
        style={{
          cursor: "pointer",
        }}
        className="tr-hover"
      >
        <td>{user.CustomerNumber}</td>
        <td>{user.FirstName}</td>
        <td>{user.LastName}</td>
      </tr>
    ));

  return (
    <div className="dashboard">
      <LeftNav />
      <div className="right-pane" style={{ backgroundColor: greyColor }}>
        <div className="si180header">
          <div className="welcome">
            <Typography
              style={{
                textAlign: "center",
                lineHeight: "1.2",
              }}
              variant={"h6"}
            >
              Welcome,{" "}
              <span
                style={{
                  color: primColor,
                }}
              >
                {currentUser.firstName} {currentUser.lastName}
              </span>
              !
            </Typography>
          </div>
          <div className="si180Search">
            <input
              className="si180btn"
              type="text"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Customer Number</th>
              <th>First Name</th>
              <th>Last Name</th>
            </tr>
          </thead>
          <tbody>{renderUsers}</tbody>
        </table>
        <Pagination
          count={Math.ceil(users.length / usersPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          style={{
            marginTop: "2rem",
            justifyContent: "center",
            display: "flex",
          }}
        />
        <div className="customer-numbers">
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            Found <strong>{customerNumbers.length}</strong> matching customers.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SI180Dashboard;
